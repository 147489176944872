import React from "react";
import {graphql} from "gatsby";
import Container from "../components/container";
import SEO from "../components/seo";
import Img from "gatsby-image";
import Content from "../components/content";
import Wrapper from "../components/wrapper";
import PortableText from "../components/portableText";
import Email from '../components/email'
import Hero from "../components/hero";

export const query = graphql`
    query TeamPageQuery {
        authors: allSanityAuthor(sort: { fields: [order], order: DESC }) {
            edges {
                node {
                    ...AuthorInfo
                }
            }
        }
    }
`;

class TeamPage extends React.Component {
    constructor(props) {
        super(props);
        const {data, errors} = props;
        this.state = {
            authors: (data || {}).authors,
        };
    }

    submitForm(e) {
        e.preventDefault();
        alert("Implementation needed!");
    }

    render() {

        const TeamMember = ({member}) => (
            <div className={'px-4 md:px-16 md:w-1/2 lg:w-1/3 mb-12'}>
                <div className='group h-full'>
                    <div className="overflow-hidden relative flex flex-col">
                        {member.image && (
                            <div className='rounded-full overflow-hidden'>
                                <Img
                                    fluid={member.image.asset.fluid}
                                    className="transform transition duration-500 ease-in-out group-hover:scale-105"
                                />
                            </div>
                        )}
                        <div className='pt-6 pb-4'>
                            <div
                                className='text-2xl font-semibold font-heading text-center mb-0'>
                                {member.name}
                            </div>
                            <div
                                className='text-sm uppercase text-brand-grey-darker text-center'>{member.position}</div>
                        </div>
                    </div>

                    {member.email && (
                        <Email
                            className='rounded-2xl bg-brand-grey-lightest text-white hover:bg-brand-grey-lighter py-2 px-4 mx-auto'
                            email={member.email}/>)}
                    <div className='px-4 pb-4 pt-6'>
                        <div className='mb-4 text-center'>
                            <div className="body-text">
                                <PortableText blocks={member._rawBio}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )

        return (
            <>
                <SEO
                    title='Våre ansatte'
                />
                <Hero
                    title='Om oss'
                />
                <Container>
                    <div className='md:px-130 pb-10'>
                        <h2 className='text-center md:text-left text-3xl text-brand-grey-darker md:text-5xl mb-6 font-semibold leading-title font-heading max-w-3xl tracking-tight order-2'>
                            Våre ansatte
                        </h2>
                    </div>

                    <div className=' md:-mx-12 pb-24 md:flex md:flex-wrap md:flex-row md:justify-center'>
                        {this.state.authors &&
                        this.state.authors.edges.map(({node}, index) => (
                            <TeamMember key={`author-${index}`} member={node}/>
                        ))}
                    </div>
                </Container>
            </>
        );
    }
}

export default TeamPage;
